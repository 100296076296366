import DomainContextParams from '@/utils/types/DomainContextParams';

export const DEAL_ENTITY_INFO_LINKS_FRAGMENT = `
  fragment dealEntityInfoLinksFragment on Deal {
    uid
    name
    title
    displayFileResource {
      schemaCode
      path
    }
    categories: categoriesInContext(context: "${DomainContextParams.deal}") {
      uid
      name
    }
  }
`;
